<template>
<v-container class="background">
    <v-row >
        <v-col v-for="(x, d) in resolver" :key="d" >
            <div>
            <v-btn block x-large  color="primary" elevation="10" :to="x.ir" >
                {{x.detalle}}
            </v-btn> 
            </div>
             
        </v-col>
    </v-row>
    <v-row justify="center">
        <!-- <cld-image public-id="/imgInspec/epps2_z6pixj.jpg" class="text-center">
	        <cld-transformation height="320" width="320" crop="scale" />
        </cld-image> -->
        <!-- <img src="../assets/uniforme_.jpg" height="320" width="320"> -->
    </v-row>
    
</v-container>
</template>

<script>
import 'sweetalert2/dist/sweetalert2.min.css';
export default {

    data() {
        return {
            t:[
                {"val":false, "verSoloAdmin": 1,"detalle": "Administracion", "ir": "/administracion"},
                {"val":false, "verSoloAdmin": 0,"detalle": "Inventario", "ir": "/menu"},
                {"val":false, "verSoloAdmin": 0,"detalle": "Revista", "ir": "/inspecciones"},
                //{"val":false, "verSoloAdmin": 1,"detalle": "Control EPP", "ir": "/bodegaepp"},
                {"val":false, "verSoloAdmin": 0,"detalle": "eNTREGA DE EQUIPOS/IMPLEMENTOS", "ir": "/eppstrabaja"},
            ] 
        }
    },
    methods: { 
    },
    computed:{
        
       resolver:function(){           
           if(this.$store.state.userlogeado.rol == 1){
               return this.t
           }else{
                const filtrado = this.t.filter(x=>{
                    return x.verSoloAdmin == 0
                })
                return filtrado
            }
       },
    },
    created() {
        
    },
    mounted() {
        this.$store.dispatch("GETALLITEMSASSIGNABLE")
        this.$store.dispatch("GETBOMBEROS")
        this.$store.dispatch('GETALLTIPOS')
        this.$store.dispatch('GETALLMARCAS')
        this.$store.dispatch('GETALLMODELOS')
        this.$store.dispatch('GETALLESTADOS')
    },
}
</script>
<style lang="scss" scoped>

body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
}

.background {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    
}

.background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.1); Color de fondo con opacidad del 50% (valor alfa = 0.5) */
    background-image: url('../assets/nuevos-carros.jpg'); /* Ruta de tu imagen de fondo */
    background-size: cover;
    filter: blur(10px); /* Ajusta el valor de desenfoque según lo desees */
}

.background > * {
    position: relative;
    z-index: 1;
    /* Estilos para el contenido por encima de la imagen de fondo */
    color: #ffffff;
    padding: 20px;
    text-align: center;
}
</style>